<template>
    <div class="container rtl">
        <div class="row py-3">
            <div class="col">
                <form @submit.prevent="addcity" autocomplete="off">
                    <div class="row py-3">
                        <div class="col"><span>زیاکردنی شار</span></div>
                        <div class="btn-group" data-toggle="buttons">
                            <label class="btn btn-primary active">
                                نوێکردنەوە
                                <input type="checkbox" v-model="resetable"  >
                            </label> 
                        </div>
                    </div>
                    <div class="row">
                      
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <label>ناوی شار</label>
                            <input v-model="city.city_name_ku" class="form-control"
                                placeholder="ناوی شار" type="text" required>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <label>اسم المدینە</label>
                            <input v-model="city.city_name_ar" class="form-control"
                                placeholder="اسم المدینە" type="text" required>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <label>City Name</label>
                            <input v-model="city.city_name_en" class="form-control"
                                placeholder="City Name" type="text" required>
                        </div>


                        <div class="col-12">
                           <div class="form-group">
                            <button type="submit" class="btn btn-primary m-2">زیادکردن</button>
                           </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="row my-3 border-top">
            <hr>
        </div>
        <div class="row">
            <div class="col">
                <table class="table table-striped" >
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">ناوی شار</th>
                            <!-- <th scope="col"> ئەکتیڤیتی شار</th> -->
                            <th scope="col">گۆڕانکاری</th>
                            <th scope="col">سڕینەوە</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in allcities" :key="index"> 
                            <td>{{item.city_name_ku}}</td>
                            <!-- <td>{{item.city_status}}</td>                           -->
                            <td>
                                <button @click="opencity(item , 'remove')" type="button" class="btn btn-danger btn-sm">
                                    سڕینەوە
                                </button>
                            </td>
                            <td>
                                <button @click="opencity(item , 'update')" type="button" class="btn btn-success btn-sm">
                                    گۆڕانکاری
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>


        <!-- Delete Modal -->
        <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">سڕینەوەی شار</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        دڵنیایت لە سڕینەوەی شار؟
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">داخستن</button>
                        <button type="button" class="btn btn-danger" @click="deletecity">سڕینەوە</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Update Modal -->
        <div class="modal fade" id="updateModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form @submit.prevent="updatecity">
                        <div class="modal-header">
                            <h5 class="modal-title">نوێکردنەوەی شار</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-6 my-1">
                                    <label>ناوی شار</label>
                                    <input v-model="selected_city.city_name_ku" class="form-control form-control-lg"
                                        placeholder="ناوی شار" type="text" required>
                                </div>
                                <div class="col-6 my-1">
                                    <label>اسم المدینە</label>
                                    <input v-model="selected_city.city_name_ar" class="form-control form-control-lg"
                                        placeholder="اسم المدینە" type="text" required>
                                </div>
                                <div class="col-6 my-1">
                                    <label>City Name</label>
                                    <input v-model="selected_city.city_name_en" class="form-control form-control-lg"
                                        placeholder="City Name" type="text" required>
                                </div>


                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">داخستن</button>
                            <button type="submit" class="btn btn-success">نوێکردنەوە</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    export default {
        data() {
            return {
                selected_city: {},
                city: {},
                resetable : false,
                msg: {
                    color: '',
                    model: false,
                    text: ''
                }
            }
        },
        computed: {
            allcities() {
                return this.$store.getters.cities
            } 
        },
        methods: {
            getcities() {
              $('#dataTable').DataTable().destroy();
                axios.get('cities/read.php')
                    .then(r => {
                        if (r.data.status == 'ok') {
                            this.$store.state.cities = r.data.rows
                            this.$nextTick( () =>{
                            $('#dataTable').DataTable();
                            })
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            addcity() {
                let params = this.city
                axios.post('cities/create.php', params)
                    .then(r => {
                        if (r.data.status == 'ok') {
                            if (this.resetable) {
                           this.city = {};
                            }
                            this.getcities()
                            this.msg = {
                                model: true,
                                text: 'شار زیادکرا',
                                color: 'success'
                            }
                            this.$toast(this.msg.text);
                        }
                        else {
                            alert(r.data.message);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            deletecity() {
                let params = {
                    city_id: this.selected_city.city_id
                }
                axios.post('cities/delete.php', params)
                    .then(r => {
                        console.log(r);
                        if (r.data.status == 'ok') {
                            $('#deleteModal').modal('hide')
                            this.getcities()
                            this.msg = {
                                model: true,
                                text: ' شار سڕایەوە',
                                color: 'danger'
                            }
                            this.$toast(this.msg.text);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            updatecity() {
                let params = this.selected_city
                axios.post('cities/update.php', params)
                    .then(r => {
                        if (r.data.status == 'ok') {
                            this.getcities()
                            $('#updateModal').modal('hide')
                            this.msg = {
                                model: true,
                                text: 'شار نوێکرایەوە',
                                color: 'success'
                            }
                            this.$toast(this.msg.text);
                        }
                        else {
                            alert(r.data);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            opencity(item, type) {
                this.selected_city = item
                if (type == 'remove') {
                    $('#deleteModal').modal('show')
                } else {
                    $('#updateModal').modal('show')
                }
            }
        },
        mounted() {
            $('#deleteModal').modal('hide')
            $('#updateModal').modal('hide')
            $('#dataTable').DataTable();
            this.getcities()
},
    }
</script>